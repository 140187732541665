import { request } from "../../client";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../../GlobalStateProvider";
import Lehrplan21Table from "../../browser/pages/Lehrplan21Table";
import { useInView } from "react-cool-inview";
import { useParams } from "react-router";
import "./AssessmentItemsPage.css"
import LoadingIndicator from "../../../../components/LoadingIndicator";
import NoItems from "../../../../components/NoItems";
import AssessmentItemCustomColumn from "./AssessmentItemCustomColumn";
import PropTypes, { InferProps } from "prop-types";
import { PageTitle } from "../../../../_start/layout/core";
import { FilterModel, IFilterModel } from "../../browser/pages/FilterModel";
import Lehrplan21FilterPane from "../../browser/pages/Lehrplan21FilterPane";
import Lehrplan21NavigationPane from "../../browser/pages/Lehrplan21NavigationPane";

export function AssessmentItemsPage(props: InferProps<typeof AssessmentItemsPage.propTypes>) {

  // Global State
  const { globalState } = useGlobalState();

  // State
  const [assessmentSchemaLevels, setAssessmentSchemaLevels] = useState<any>([]);
  const [assessmentSchemaLevelsFilter, setAssessmentSchemaLevelsFilter] = useState<any>([]);
  const [assessment, setAssessment] = useState<any>(null);
  const [filter] = useState<FilterModel>(new FilterModel({} as IFilterModel));
  const [items, setItems] = useState([]);
  const [pageSize] = useState(10);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [hasNoItems, setHasNoItems] = useState(true);
  const [remarks, setRemarks] = useState([]);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadSummary, setReloadSummary] = useState(new Date());
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);
  const [saveItemData, setSaveItemData] = useState<any>(null);

  // Route params
  const { id } = useParams<{ id: string }>();

  // Infinite scroll
  const { observe } = useInView({
    rootMargin: "50px 0px",
    onEnter: ({ unobserve, observe }) => {
      unobserve();
      onNextPage(() => {
        observe();
      }, false);
    },
  });

  // Mount
  useEffect(() => {

    fetchItemApi()
      .then((result: any) => {
        setAssessment(result);

        // Define default filters
        if (!filter.planId) {
          filter.planId = result?.linkedPlanId;
        }
        if (!filter.isPlanned && filter.planId) {
          filter.isPlanned = true;
        }

        // Items
        fetchItems(false, filter, null, false);

        // Lookups
        fetchAssessmentSchemaLevelsApi(result.assessmentSchemaId).then((res: any) => {

          // Add empty level
          var levels = res.levels;
          levels.unshift({
            id: "00000000-0000-0000-0000-000000000000",
            name: "ohne Beurteilung",
            percentage: 0,
          });
          setAssessmentSchemaLevels(levels);

          // Different labels for filter
          var levelsFilter = levels.map((l: any) => {

            if (l.name.startsWith("ohne")) {
              return l;
            }
            else {
              return {
                id: l.id,
                name: `bis und mit ${l.name}`,
              }
            }
          });
          setAssessmentSchemaLevelsFilter(levelsFilter);

          // Enable infinite scroll
          setInitialLoadCompleted(true);

        }).catch((ex: any) => { alert(`Error: ${ex})`) });

      })
      .catch((ex: any) => {
        alert(`Error: ${ex})`);
      });

  }, []);

  /* Delayed saving */
  useEffect(() => {
    const debouncedSaveItem = setTimeout(() => {
      if (saveItemData) {
        saveItem(saveItemData.level, saveItemData.id, saveItemData.item, saveItemData.assessmentSchemaLevelId, saveItemData.remarks);
      }
    }, 200);

    return () => clearTimeout(debouncedSaveItem);
  }, [saveItemData]);

  // Event handlers
  const onFilterChanged = function (reloadAssessmentSchemaLevel: boolean) {
    fetchItems(false, filter, null, false);

    if (reloadAssessmentSchemaLevel) {

    }
  }

  const onSaveItem = function (level: string, id: string, item: any, assessmentSchemaLevelId: any, remarks: any) {
    setSaveItemData({ level, id, item, assessmentSchemaLevelId, remarks });
  }

  const onNextPage = function (postFetchFunc: any, loadAll: boolean) {

    // Initial load not completed yet?
    if (!initialLoadCompleted) {
      return;
    }

    fetchItems(true, filter, postFetchFunc, loadAll);
  }

  function onRenderCustomColumns(item: any, level: string, rowId: string): JSX.Element {

    // Lookup remark
    var remark: any = findRemark(level, rowId);
    var levelImages: any = findImages(level, rowId);
    return <>
      {id && <AssessmentItemCustomColumn assessmentSchemaLevels={assessmentSchemaLevels} assessmentId={id} rowId={rowId} level={level} isReadOnly={props.isReadOnly || assessment?.assessmentStatusIsReadOnly} onSaveItem={onSaveItem} item={item} reloadSummary={reloadSummary} remark={remark} images={levelImages} imageBaseUrl={`${globalState.apiBaseUrl ?? ""}/assessment/${id}/image`} imagePostUrl={`${globalState.apiBaseUrl}/assessment/${id}/image?level=${level}&id=${rowId}`} />}
    </>
  }

  // Functions
  const fetchItems = function (appendItems: boolean, filter: any, postFetchFunc: any, loadAll: boolean) {

    setIsLoading(true);

    if (!items || items.length === 0) {
      appendItems = false;
    }

    var offset = (appendItems === false ? 0 : items.length);
    var next = (loadAll ? 2147483647 : pageSize);

    fetchItemsApi(offset, next, filter)
      .then((result: any) => {

        if (result.queryId < (globalState.queryId ?? 0)) {
          // Ignore results, seem to be too late
          console.log(`Skipped query id ${result.queryId} as running alredy query id ${globalState.queryId}.`);
          return;
        }

        var resultItems = (appendItems === false ? result.items : [...items, ...result.items]);
        setItems(resultItems);
        setRemarks(result.remarks);
        setImages(result.images);
        setIsLoading(false);
        setHasMoreItems((result.items?.length ?? 0) === next);
        setHasNoItems(resultItems.length === 0);
        if (postFetchFunc) postFetchFunc();
      })
      .catch((ex: any) => {
        alert(`Error: ${ex}`);
        setIsLoading(false);
      });
  };

  const clearItems = function () {
    setItems([]);
    setRemarks([]);
    setImages([]);
  };

  const findRemark = function (level: string, id: string): any {
    if (!remarks || remarks.length === 0)
      return null;

    return remarks.find((r: any) => r.level === level && r.id === id);
  }

  const findImages = function (level: string, id: string): any {
    if (!images || images.length === 0)
      return null;

    return images.filter((r: any) => r.level === level && r.id === id);
  }

  const saveItem = function (level: string, id: string, item: any, assessmentSchemaLevelId: any, remarks: any) {

    var doReloadSummary = false;

    if (assessmentSchemaLevelId !== null) {
      item.assessmentSchemaLevelId = assessmentSchemaLevelId;

      if (item.istGrundanspruch) {
        doReloadSummary = true;
      }
    } else {
      assessmentSchemaLevelId = item.assessmentSchemaLevelId;
    }

    if (remarks !== null) {
      item.remarks = remarks;
    } else {
      remarks = item.remarks;
    }

    saveItemApi(level, id, {
      assessmentId: item.assessmentId,
      assessmentSchemaLevelId: assessmentSchemaLevelId,
      remarks: remarks,
    })
      .then(() => {
        // Save confirmation

        // If schema level has been updated, reload summary
        if (doReloadSummary) {
          setReloadSummary(new Date());
        }

      })
      .catch((ex: any) => {
        if (ex && ex.response && ex.response.details) {
          alert(`Error: ${ex})\n\n${ex.response.details}`);
        } else {
          alert(`Error: ${ex})`);
        }
      });
  }

  // Web api calls
  const fetchItemApi = async function () {
    return request(`${globalState.apiBaseUrl}/assessment/${id}`, globalState.authToken);
  }
  const fetchAssessmentSchemaLevelsApi = async function (assessmentSchemaId: string) {
    return request(`${globalState.apiBaseUrl ?? ""}/masterdata/assessmentschema/${assessmentSchemaId}`, globalState.authToken);
  }
  const fetchItemsApi = async function (offset: number, next: number, filter: FilterModel | null) {
    globalState.queryId = (globalState.queryId ?? 0) + 1;
    return request(`${globalState.apiBaseUrl ?? ""}/assessment/${id}/browse?queryId=${globalState.queryId}&offset=${offset}&next=${next}&${filter?.toUrlQueryParams()}`, globalState.authToken);
  }
  const saveItemApi = async function (level: any, innerId: any, data: any) {
    // Create or Update
    const options = { method: "PUT", payload: data, };
    return request(`${globalState.apiBaseUrl}/assessment/${id}/item?level=${level}&id=${innerId}`, globalState.authToken, options);
  }

  return (
    <>
      <div className="lp21-panes">
        <Lehrplan21FilterPane filter={filter} onFilterChanged={onFilterChanged} onClearItems={clearItems} isLoading={isLoading} filterAutoSaveSuffix="Lernstandserfassung" fixedLehrplanId={assessment?.lehrplanId} fixedAssessmentId={assessment?.id} assessmentSchemaLevels={assessmentSchemaLevelsFilter} />
        <Lehrplan21NavigationPane items={items} levelUntil={filter?.levelUntil} pageSize={pageSize} hasMoreItems={hasMoreItems} onLoadNext={() => { onNextPage(null, false) }} showLoadAll={(filter?.fachbereichId ?? "") !== ""} onLoadAll={() => { onNextPage(null, true) }} />
      </div>
      <div className="card lp21-assessmentitemspage">
        <PageTitle>{`Lernstandserfassung ${assessment?.name ?? ""}`}</PageTitle>
        <Lehrplan21Table items={items} levelUntil={filter?.levelUntil} onRenderCustomColumns={onRenderCustomColumns} renderLinks={true} renderHeaderRow={false} />
        {!isLoading && hasMoreItems && <button ref={observe} className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary" onClick={() => { onNextPage(null, false) }}>Nächste {pageSize} laden</button>}
        {isLoading && <div className="row m-3"> <LoadingIndicator /></div>}
        {!isLoading && hasNoItems && <NoItems />}
      </div>
    </>
  );
};

AssessmentItemsPage.propTypes = {
  isReadOnly: PropTypes.bool.isRequired
};

export default AssessmentItemsPage;